<template>

  <div class="layout-container-main">
    <!-- Sidebar -->
    <aside :class="`${is_expanded ? 'is-expanded' : ''}`">
      <!-- Logo Srcs logoURL -->
      <div class="logo">
        <img :src="LogoNew" alt="Logo" />
        <div class="logo2">
          <img :src="LogoNew" alt="logo2" />
          
        </div>
      </div>

      <!-- Menu icons and routes -->
      <div class="menu">
        <router-link to="/" class="button">
          <span class="material-symbols-rounded">home</span>
          <span class="text">Home</span>
        </router-link>

        <router-link to="/alerts" class="button">
          <span class="material-symbols-rounded">notifications</span>
          <span class="text">Alerts</span>
        </router-link>

        <router-link to="/masterdata" class="button">
          <span class="material-symbols-rounded">database</span>
          <span class="text">CMS</span>
        </router-link>

        <router-link to="/null" class="button">
          <span class="material-symbols-rounded">settings</span>
          <span class="text">Settings</span>
        </router-link>

 

        <router-link to="/Logout" @click.prevent="handleLogout" class="button">
          <span class="material-symbols-rounded">logout</span>
          <span class="text">Logout</span>
        </router-link>

      </div>
      <div class="flex"></div>

      <!-- Menu toggle -->
      <div class="menu-toggle-wrap">
        <button class="menu-toggle" @click="ToggleMenu">
          <span class="material-symbols-rounded">double_arrow</span>
        </button>
      </div>
    </aside>

    <!-- Main Content -->
    <div class="main-content">

    </div>
  </div>
</template>

<script setup>
// Importing assets
import { ref } from 'vue';
import axios from 'axios';
//import logoURL from '../assets/images/Logo.png';
import LogoNew from '../assets/images/Logo2.png';

const is_expanded = ref(localStorage.getItem('is_expanded') === 'true');


const ToggleMenu = () => {
  is_expanded.value = !is_expanded.value;
  localStorage.setItem('is_expanded', is_expanded.value);
};

const handleLogout = async () => {
  try {
    const response = await axios.post('/accounts/api/logout/');
    if (response.data.success) {
      sessionStorage.removeItem('token');  // Remove the authentication token
      sessionStorage.removeItem('refresh_token');  // Remove the refresh token if stored
      window.location.href = '/login'; // Redirect to login page after logout
    }
  } catch (error) {
    console.error('Logout failed:', error);
  }
};

</script>


<style lang="scss" scoped>
/* Container for Sidebar and Main Content */
.layout-container-main {
  display: flex;
  min-height: 100vh;
}

/* Sidebar Styles */
aside {
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 1;
  background-color: var(--dark);
  color: var(--light);
  width: calc(4rem + 60px);
  overflow: hidden;
  min-height: 100vh;
  padding: 0;
  border-color: #1E145E;
  border-width: 0px;
  transition: 0.2s ease-in-out;
}

aside.is-expanded {
  width: var(--sidebar-width); /* Use your desired width when expanded */
}

/* Main Content Styles */
.main-content {
  flex: 1;
  margin-left: calc(4rem + 60px); /* Default margin when sidebar is collapsed */
  padding: 0rem;
  transition: margin-left 0.3s ease;
}

aside.is-expanded + .main-content {
  margin-left: var(--sidebar-width); /* Adjust margin when the sidebar expands */
}

/* Existing styles for sidebar elements */
.logo {
  margin-top: 4rem;
  margin-bottom: 2rem;
  margin-left: 1rem;

  img {
    width: 100px;
  }
}

.logo2 {
  margin-top: -4rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  visibility: hidden;

  img {
    width: 300px;
  }
}

aside.is-expanded .logo {
  visibility: hidden;
}

aside.is-expanded .logo2 {
  visibility: visible;
}


.flex {
  flex: 1 1 0%;
}

.material-symbols-rounded {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

.menu-toggle-wrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
  margin-right: 3rem;
  position: relative;
  top: 0;
  transition: 0.2s ease-in-out;
}

.menu-toggle {
  transition: 0.2s ease-in-out;

  .material-symbols-rounded {
    font-size: 2rem;
    color: var(--light);
    transition: 0.2s ease-out;
  }

  &:hover {
    .material-symbols-rounded {
      color: var(--primary);
      transform: translateX(0.3rem);
    }
  }
}

h3,
.button .text {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25px;
}
aside.is-expanded .button .text {
  opacity: 1;

  aside.is-expanded .logout-button .text {
    opacity: 0;
  }
}

h3 {
  color: var(--light);
  font-size: 0.875rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
}



.menu {
  margin: 0 -1rem;

  .button {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  padding: 0.5rem 1.3rem;
  margin-top: 1rem;
  overflow: hidden;
  position: relative;

  .material-symbols-rounded {
    margin-left: 36px;
    font-size: 2.3rem;
    color: var(--light);
    transition: 0.2s ease-in-out;
  }

  .text {
    color: var(--light);
    transition: 0.2s ease-in-out;
    margin-left: 2rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0; /* Set the pseudo-element to start from the top */
    left: 0; /* Set the pseudo-element to start from the left */
    width: 0; /* Start with 0 width */
    height: 100%; /* Ensure full height */
    background-color: var(--dark-alt);
    transition: 0.3s ease-in-out;
    z-index: -1;
    border-radius: 20px; /* Optional: Adjust based on your preference */
  }

  &:hover::before,
  &.router-link-exact-active::before {
    width: 100%; /* Expand the width to cover the entire button */
    height: 100%; /* Ensure it covers the full height */
  }

  &:hover,
  &.router-link-exact-active {
    .material-symbols-rounded,
    .text {
      color: var(--primary); /* Change the color on hover */
    }
  }

  &.router-link-exact-active {
    background-color: transparent;
    border-right: 0px solid var(--primary);

    .material-symbols-rounded,
    .text {
      color: var(--primary);
    }
  }
}
}



.footer {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.logout-button {
  @extend .button;
  border: none;
  cursor: pointer;

  margin-left: 0rem;
  margin-bottom: 0.2rem;
  position: relative;
  

  .material-symbols-rounded {
    margin-right: 1rem;

    .text {
    display: none; 
  }
   
  }
  

  &:hover {
    background-color: var(--dark-alt);
    color: var(--primary);
  }
}

aside.is-expanded .menu-toggle {
  transform: rotate(-180deg);
}
</style>

