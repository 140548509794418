<template>
  <div class="flex mt-20 ml-12 space-x-8">
    <!-- Button Group for Log Filters -->
    <div class="flex flex-col space-y-4">
      <h1 class="text-4xl font-thin my-6 text-start mt-0 font-extrabold">Alerts</h1>

      <button
        id="logs+errorlog"
        @click="setFetchString('logs/get_error_log_details')"
        :class="{
          'bg-blue-500 hover:bg-blue-500': activeButton === 'logs/get_error_log_details',
          'bg-blue-400': activeButton !== 'logs/get_error_log_details',
        }"
        class="p-3 text-white rounded-lg w-[150px] h-[50px]"
      >
        ALL
      </button>

      <!-- Red Logs Button with Info Icon -->
      <button
        id="Red+errorlogs"
        @click="setFetchString('logs/get_red_error_log_details')"
        :class="{
          'bg-red-700 hover:bg-red-700': activeButton === 'logs/get_red_error_log_details',
          'bg-red-600': activeButton !== 'logs/get_red_error_log_details',
        }"
        class="flex items-center justify-between text-white p-3 rounded-lg w-[150px] h-[50px] space-x-2"
      >
        <span></span>
      </button>

      <!-- Amber Logs Button with Info Icon -->
      <button
        id="Amber+errorlogs"
        @click="setFetchString('logs/get_amber_error_log_details')"
        :class="{
          'bg-amber-600 hover:bg-amber-600': activeButton === 'logs/get_amber_error_log_details',
          'bg-amber-500': activeButton !== 'logs/get_amber_error_log_details',
        }"
        class="flex items-center justify-between p-3 text-white rounded-lg w-[150px] h-[50px] space-x-2"
      >
        <span></span>
      </button>

      <!-- Green Logs Button with Info Icon -->
      <button
        id="Green+errorlogs"
        @click="setFetchString('logs/get_green_error_log_details')"
        :class="{
          'bg-green-600 hover:bg-green-600': activeButton === 'logs/get_green_error_log_details',
          'bg-green-500': activeButton !== 'logs/get_green_error_log_details',
        }"
        class="flex items-center justify-between p-3 text-white rounded-lg w-[150px] h-[50px] space-x-2"
      >
        <span></span>
       
      </button>

      <!-- Guide Button to Open Overlay with Structured Content -->
      <button
        @click="openOverlay('<h3 style=\'color: red;\'><b>RED Alert Report:</b></h3><p>A system alert has been triggered after detecting the listed faults within the last 24 hours. <b>IMMEDIATE </b>maintenance may be required to prevent damage or disruption, unless the faults are self-corrected.<br><br><b>Action suggested:</b> Maintenance must be performed <b> at once</b> to address valid faults.<br><br><h3 style=\'color: orange;\'><b>AMBER Alert Report:</b></h3><p>A system alert has been triggered after detecting the listed faults that occurred <b>3 times</b> within the last 24 hours. While these faults are not immediately critical, maintenance is required within 24 hours to avoid potential escalation,unless the faults are self-corrected.<br><br><b>Action Suggested:</b> Maintenance to be performed <b> within the next 24 hours</b> to ensure continued system stablity<br><br><h1 style=\'color:Green;\'><b>GREEN Alert Report:</b></h1 ><p>Our system has detected the faults that occured <b>3 times</b> within the last <b>1-168 hours</b> (7 days). While this issue does not pose an immediate risk, we recommend <b>maintenance within 1-3 days</b> to ensure the system remains fully operational and to prevent further escalation, <b>unless the faults are self-corrected</b>. The Status will be shown when it appeared 3 times + in the last 7 days.<br><br><b>Action Suggested:</b> Maintenance to be performed <b> within 1-3 days</b> to prevent the fault becoming more severe.<br>')"
        class="p-4 ml-0  bg-gray-800 text-white rounded-lg w-[150px] h-[50px]"
      >
        GUIDE
      </button>
    </div>

    <!-- Table and Date Range Selector Container -->
    <div class="flex justify-between w-full">
      <!-- Data Display Component (Table) -->
      <div class="text-left w-4/4">
        <button
          @click="setDateRange(1)"
          class="p-2 text-gray-500 hover:text-blue-500"
          :class="{ 'font-semibold text-blue-600': activeRange === 1 }"
        >
          Today
        </button>

        <button
          @click="setDateRange(7)"
          class="p-2 text-gray-500 hover:text-blue-500"
          :class="{ 'font-semibold text-blue-600': activeRange === 7 }"
        >
          Last 7 Days
        </button>

        <button
          @click="setDateRange('custom')"
          class="p-2 text-gray-500 hover:text-blue-500"
          :class="{ 'font-semibold text-blue-600': activeRange === 'custom' }"
        >
          Custom
        </button>

        <!-- Custom Date Range Inputs and Apply Button - Only Visible if 'Custom' is Active -->
        <div v-if="activeRange === 'custom'" class="flex space-x-2 mt-2 items-center">
          <input
            type="date"
            id="start-date"
            v-model="startDate"
            class="p-2 rounded-md border border-gray-300"
          />
          <input
            type="date"
            id="end-date"
            v-model="endDate"
            class="p-2 rounded-md border border-gray-300"
          />
        </div>
        <AlertTable :fetch-string="fetchString" :start-date="startDate" :end-date="endDate" />
      </div>
    </div>

    <!-- Overlay Window -->
    <div v-if="isOverlayVisible" class="fixed inset-0 flex items-left text-left justify-center bg-black bg-opacity-50">
      <div class="bg-white p-6 rounded-lg shadow-lg mt-6 mb-6 w-2/3 max-w-full overflow-y-auto">
        <div v-html="overlayContent"></div>
        <button @click="closeOverlay" class="mt-4 p-2 bg-blue-500 text-white rounded-lg">Close</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import AlertTable from "@/components/AlertTable.vue";

// Reactive data for date range and log filters
const fetchString = ref('logs/get_error_log_details');
const activeButton = ref('logs/get_error_log_details');
const startDate = ref('');
const endDate = ref('');
const activeRange = ref('custom'); // Track which range is active

// Overlay control
const isOverlayVisible = ref(false);
const overlayContent = ref('');

// Open overlay with specific HTML content
function openOverlay(content) {
  overlayContent.value = content;
  isOverlayVisible.value = true;
}

// Close overlay
function closeOverlay() {
  isOverlayVisible.value = false;
}

// Set the date range based on preset options
function setDateRange(days) {
  const today = new Date();
  activeRange.value = days;

  if (days === 'custom') {
    startDate.value = '';
    endDate.value = '';
  } else {
    startDate.value = new Date(today.setDate(today.getDate() - days)).toISOString().split('T')[0];
    endDate.value = new Date().toISOString().split('T')[0];
  }
}

// Set fetch string and active button
function setFetchString(defType) {
  fetchString.value = defType;
  activeButton.value = defType;
}
</script>

<style scoped>
/* Optional: Add additional styles for better hover effects */
button:hover {
  cursor: pointer;
}
</style>
